function _createForOfIteratorHelperLoose(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (it)
    return (it = it.call(o)).next.bind(it); if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") {
    if (it)
        o = it;
    var i = 0;
    return function () { if (i >= o.length)
        return { done: true }; return { done: false, value: o[i++] }; };
} throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o)
    return; if (typeof o === "string")
    return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor)
    n = o.constructor.name; if (n === "Map" || n === "Set")
    return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n))
    return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length)
    len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
} return arr2; }
export function getErrorMsg(fieldName, result, i18n, t) {
    var key1 = fieldName.toUpperCase() + "." + (result === null || result === void 0 ? void 0 : result.errorCode);
    var key2 = fieldName.toUpperCase() + ".LABEL";
    var label = i18n.exists(key2) ? t(key2) : fieldName;
    var errorMsg = i18n.exists(key1) ? t(key1) : t("COMMON." + (result === null || result === void 0 ? void 0 : result.errorCode)).replace("{fieldName}", label);
    if (result !== null && result !== void 0 && result.validatorValue) {
        var validatorValue = i18n.exists(result.validatorValue) ? t(result.validatorValue) : result.validatorValue;
        errorMsg = errorMsg.replace("{validatorValue}", validatorValue);
    } // console.log("getErrorMsg for " + key1, errorMsg);
    return errorMsg;
}
export function formikValidateBuilder(rules, i18n, t) {
    return function (values) {
        var errors = {}; // call validate Rules, return [fieldName: string]: ValiationResult
        var result = validateRules(values, rules);
        Object.keys(result).map(function (fieldName) {
            errors[fieldName] = getErrorMsg(fieldName, result[fieldName], i18n, t);
        });
        return errors;
    };
}
export function validateRules(values, rules) {
    var errors = {};
    Object.keys(rules).map(function (fieldName) { for (var _iterator = _createForOfIteratorHelperLoose(rules[fieldName].validators), _step; !(_step = _iterator()).done;) {
        var validator = _step.value;
        var validationResult = validator(fieldName, values);
        if (validationResult) {
            errors[fieldName] = validationResult;
            break;
        }
    } }); // console.log("validateRules", errors);
    return errors;
}
